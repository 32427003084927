.profile__edit-info {
	width: 18px;
	height: 18px;
	border: 1px solid #fff;
	background: url(../../../images/edit.svg) 50% 50% no-repeat;
	background-size: 8px 8px;
	margin-left: 16px;
}


@media screen and (min-width: 480px) {
	
	.profile__edit-info {
		width: 21px;
		height: 21px;
		background-size: 10px 10px;
		margin-left: 24px;
	}
	
}


@media screen and (min-width: 918px) {
	
	.profile__edit-info {
		margin: 0 32px;
		width: 24px;
		height: 24px;
		background-size: 10px 10px;
	}
	
}