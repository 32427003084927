@import url(./__avatar/profile__avatar.css);
@import url(./__info/profile__info.css);
@import url(./__name/profile__name.css);
@import url(./__about/profile__about.css);
@import url(./__edit-info/profile__edit-info.css);
@import url(./__edit-avatar/profile__edit-avatar.css);
@import url(./__add-image/profile__add-image.css);


.profile {
	margin: 42px 19px 0 19px;
	display: flex;
	align-items: center;
	flex-direction: column;
}


@media screen and (min-width: 480px) {
	
	.profile {
		margin: 40px 18px 0 18px;
	}
	
}


@media screen and (min-width: 918px) {

	.profile {
		flex-direction: row;
	}
	
}



