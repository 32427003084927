@import url(./__logo/header__logo.css);
@import url(./__account-menu/header__account-menu.css);
@import url(./__account-menu/_logged-in/header__account-menu_logged-in.css);
@import url(./__account-menu/_active/header__account-menu_active.css);
@import url(./__account-item/header__account-item.css);
@import url(./__account-item/_logged-in/header__account-item_logged-in.css);
@import url(./__account-logout/header__account-logout.css);
@import url(./__menu-toggle/header__menu-toggle.css);
@import url(./__menu-toggle/_selected/header__menu-toggle_selected.css);



.header {
  margin: 0 0 0 0;
  border-bottom: 1px solid rgb(84, 84, 84, .7);
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "menu menu"
    "logo menu-side";
}


@media screen and (min-width: 480px) {
	
	.header {
    height: 56px;
		margin: 28px 30px 0 30px; /* instead of auto margins on side, this keeps some space on either side at medium resolutions */
    padding: 0;
    display: flex;
    justify-content: space-between;
	}
	
}


@media screen and (min-width: 918px) {

	.header {
		margin: 45px 20px 0 20px;
		height: 74px;
	}

}