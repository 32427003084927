.profile__add-image {
	display: block;
	width: 100%;
	max-width: 282px;
	min-width: 150px;
	height: 50px;
	border: 2px solid #FFFFFF;
	box-sizing: border-box;
	border-radius: 2px;
	margin-top: 36px;
	background: url(../../../images/add.svg) 50% 50% no-repeat;
	background-size: 16px 16px;
}


@media screen and (min-width: 918px) {
	
	.profile__add-image {
		margin: 0 0 0 auto;
		width: 150px;
		background-size: 22px 22px;
	}
	
}