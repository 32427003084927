.photo__delete {
	display: block;
	position: absolute;
	top: 15px;
	right: 12px;
	width: 23px;
	height: 26px;
	background: url(../../../images/delete.svg) 50% 50% no-repeat;
	background-size: 18px 20px;
}
