.modal__input {
	color: #000;
	border-bottom: 1px solid rgba(0, 0, 0, .2);
}

.modal__input:hover, .modal__input:focus {
	border-color: rgba(0, 0, 0, .8);
}

.modal__input::-webkit-input-placeholder {
	color: rgba(0, 0, 0, .3);
}

.modal__input:-ms-input-placeholder {
	color: rgba(0, 0, 0, .3);
}

.modal__input::placeholder {
	color: rgba(0, 0, 0, .3);
}