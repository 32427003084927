.profile__name {
	font-weight: 500;
	font-size: 22px;
	line-height: 56px;
	margin: 0;
	text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
	max-width: 240px;
	padding-left: 36px;
	box-sizing: border-box;
}


@media screen and (min-width: 480px) {
	
	.profile__name {
		font-size: 32px;
		margin: 0 0 4px;
		max-width: 400px;
		padding-left: 45px;
	}
	
}

@media screen and (min-width: 618px) {
	.profile__name {
		max-width: 490px;
	}
}


@media screen and (min-width:918px) {
	
	.profile__name {
		font-size: 42px;
		padding-left: 0;
	}
	
}