.modal__button_disabled {
	background: #fff; 
	color: #000; 
	border: 1px solid #000;
	opacity: .2;
}


.modal__button_disabled:hover, .modal__button_disabled:focus {
	opacity: .2;
}