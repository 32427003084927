.profile__avatar {
	width: 120px;
	height: 120px;
  border-radius: 50%; 
  object-fit: cover;
  display: block;
}


@media screen and (min-width: 480px) and (max-width: 917px) {
	
	.profile__avatar {
		width: 200px;
		height: 200px;
	}
	
}


@media screen and (min-width: 918px) {
	
	.profile__avatar {
		margin-right: 30px;
	}
	
}